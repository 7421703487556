document.addEventListener('DOMContentLoaded', async () => {

    // nav
    const body = document.querySelector('body');
    const navbarToggler  = document.querySelector('.navbar-toggler');

    const mqSmallScreen = window.matchMedia( "(max-width: 1024px)" );
    navbarToggler.addEventListener('click', function() {
        if (mqSmallScreen.matches) {
            if (navbarToggler.classList.contains("collapsed")) {
                body.classList.remove("overflow-hidden");
            } else {
                body.classList.add("overflow-hidden");
            }
        }
    });


    // Get Element Height utility function
    const getElementHeight = (elem) => {
        return Math.max(elem.scrollHeight, elem.offsetHeight, elem.clientHeight);
    };


    const divIndextext = document.querySelector('#indextext');
    const bodyHomepage = document.querySelector('.body-homepage');
    const main = document.querySelector('#main');

    const setIndexTextHeight = () => {
        let divIndextextHeight = getElementHeight(divIndextext);
        divIndextext.style.height = (divIndextextHeight + 'px');
    };

    // indexText einblenden
    const indexText = () => {
        const localStorage = window.localStorage;
        if(typeof(bodyHomepage) != 'undefined' && bodyHomepage != null && document.body.contains(divIndextext)) {
            if (localStorage.getItem("indextext") == "hide") {
                bodyHomepage.classList.remove("show-indextext");
                main.classList.remove("is-blurred");
            }
            else {
                bodyHomepage.classList.add("show-indextext");
                main.classList.add("is-blurred");
                document.querySelectorAll('.arrow-up').forEach(function(el) {
                    el.addEventListener('click', function() {
                        divIndextext.style.height = 0;
                        main.classList.remove("is-blurred");
                        bodyHomepage.classList.remove("show-indextext");
                        localStorage.setItem('indextext', 'hide');
                    });
                });
                divIndextext.classList.remove("d-none");
                setIndexTextHeight();
            }
        }
    }

    // Init indexText function
    indexText();

    // Resize handler
    window.addEventListener('resize', function() {
        indexText();
    });

    const weiter = document.querySelector('.weiter');
    const lead5lines = document.querySelector('.lead5lines');
    if(typeof(weiter) != 'undefined' && weiter != null) {
        weiter.addEventListener('click', function() {
            lead5lines.classList.remove("lead5lines");
        });
    }

});

import Lightbox from 'bs5-lightbox';

document.querySelectorAll('.lb').forEach(el => el.addEventListener('click', Lightbox.initialize));
