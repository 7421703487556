// Importing scripts
import * as bootstrap from 'bootstrap';

import './js/csrf';
import './js/cookie-consent';
import './js/scripts';

// Importing styles (bootstrap + customization)
import './scss/bootstrap.scss';
import './scss/styles.scss';

//import '@splidejs/splide/css/core';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
